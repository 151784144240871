.card {
    position: relative;
    width: 100%;
    height: auto;
    overflow: hidden;
    border-radius: 1rem;
}

.cardImage {
    width: 100%;
    height: auto;
    display: block;
    transition: transform 0.5s ease;
}

.card:hover .cardImage {
    transform: scale(1.1);
}

.overlay {
    position: absolute;
    bottom: 0;
    left: 0;
    height: 100%;
    width: 100%;
    padding: 20px;
    box-sizing: border-box;
    background-color: rgba(0, 0, 0, 0.5);
    color: #fff;
    transition: opacity 0.5s ease;
}

.content {
    display: flex;
    flex-direction: column-reverse;
    height: 100%;
    align-items: start;
}

.icon{
    max-width: 10vw;
}

@media (max-width: 576px) {
    .icon{
        max-width: 30vw;
    }
  }